import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  collection,
  getDocs,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";
import Countdown from "./Countdown";
import "./Sorteo.css";
import { toast } from "react-toastify";
import { GoTrash } from "react-icons/go";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import cargaGif from "../assets/carga.gif";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Sorteo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [sorteo, setSorteo] = useState(null);
  const [boletos, setBoletos] = useState([]);
  const [seleccionados, setSeleccionados] = useState([]);
  const [usuario, setUsuario] = useState({
    nombre: "",
    telefono: "",
    pais: "mx",
  });
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalBoletos, setTotalBoletos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [hasParticipatedInOtherFreeRaffles, setHasParticipatedInOtherFreeRaffles] = useState(false);
  const [registeredPhones, setRegisteredPhones] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(true);
  const pageSize = 60;
  const [expired, setExpired] = useState(false);
  const [isInactive, setIsInactive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isNumberFound, setIsNumberFound] = useState(true);
  const [disableGridSelection, setDisableGridSelection] = useState(false);
  const [tempBoletos, setTempBoletos] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL || "https://panel.nuevomillonario.com";
  

  const fetchBoletos = async (pageIndex, filterAvailable = false) => {
    setLoading(true);
    try {
      const disponiblesRef = doc(db, `sorteos/${id}/boletos`, "disponibles");
      const apartadosRef = doc(db, `sorteos/${id}/boletos`, "apartados");
      const compradosRef = doc(db, `sorteos/${id}/boletos`, "comprados");

      const disponiblesDoc = await getDoc(disponiblesRef);
      const apartadosDoc = await getDoc(apartadosRef);
      const compradosDoc = await getDoc(compradosRef);

      let disponibles = [];
      let apartados = [];
      let comprados = [];

      if (disponiblesDoc.exists()) {
        disponibles = disponiblesDoc.data().numeros.map((numero) => ({
          numero,
          estado: "disponible",
        }));
      }
      if (apartadosDoc.exists()) {
        apartados = apartadosDoc.data().numeros.map((numero) => ({
          numero,
          estado: "apartado",
        }));
      }
      if (compradosDoc.exists()) {
        comprados = compradosDoc.data().numeros.map((numero) => ({
          numero,
          estado: "comprado",
        }));
      }

      let boletosList = [...disponibles, ...apartados, ...comprados];

      if (filterAvailable) {
        boletosList = boletosList.filter((boleto) => boleto.estado === "disponible");
      }

      const start = pageIndex * pageSize;
      const paginatedBoletos = boletosList
        .sort((a, b) => a.numero.localeCompare(b.numero))
        .slice(start, start + pageSize);

      setBoletos(paginatedBoletos);
    } catch (error) {
      console.error("Error al obtener boletos:", error);
    }
    setLoading(false);
  };

  const fetchAllBoletos = async () => {
    setLoading(true);
    try {
      const disponiblesRef = doc(db, `sorteos/${id}/boletos`, "disponibles");
      const disponiblesDoc = await getDoc(disponiblesRef);
      let disponibles = [];

      if (disponiblesDoc.exists()) {
        disponibles = disponiblesDoc.data().numeros.map((numero) => ({
          numero,
          estado: "disponible",
        }));
      }
      setBoletos(disponibles);
    } catch (error) {
      console.error("Error al obtener boletos disponibles:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchSorteo = async () => {
      try {
        const sorteoDocRef = doc(db, "sorteos", id);
        const sorteoDoc = await getDoc(sorteoDocRef);
        if (sorteoDoc.exists()) {
          const sorteoData = sorteoDoc.data();
          if (sorteoData.fechaFin) {
            sorteoData.fechaFin = sorteoData.fechaFin.toDate();
          }
          setSorteo(sorteoData);
          setExpired(sorteoData.fechaFin ? new Date() > sorteoData.fechaFin : false);

          if (sorteoData.paquetes && sorteoData.paquetes.length > 0) {
            setShowPackageModal(true);
          }

          if (sorteoData.telefonosRegistrados) {
            setRegisteredPhones(sorteoData.telefonosRegistrados || []);
          }

          // Verificar inactividad inmediatamente
          checkInactivity(sorteoData.inactiveDays, sorteoData.inactiveHours);

          // Inicia un intervalo para verificar la inactividad cada 60 segundos
          const intervalId = setInterval(() => {
            checkInactivity(sorteoData.inactiveDays, sorteoData.inactiveHours);
          }, 60000);

          await fetchBoletos(0);

          // Limpia el intervalo cuando el componente se desmonte
          return () => clearInterval(intervalId);
        } else {
          console.log("No se encontró el documento.");
        }
      } catch (error) {
        console.error("Error al obtener el sorteo:", error);
      }
    };

    const fetchBoletosCount = async () => {
      try {
        const disponiblesRef = doc(db, `sorteos/${id}/boletos`, "disponibles");
        const disponiblesDoc = await getDoc(disponiblesRef);
        if (disponiblesDoc.exists()) {
          const disponiblesData = disponiblesDoc.data();
          setTotalBoletos(disponiblesData.numeros.length);
        }
      } catch (error) {
        console.error("Error al obtener el conteo de boletos:", error);
      }
    };

    fetchSorteo();
    fetchBoletosCount();
  }, [id]);

  
  

  const calcularTiempoRestante = (start) => {
    const currentTime = new Date();
    const startTime = new Date();
  
    // Separar hora y minutos del "start" y ajustar para el tiempo final
    const [startHour, startMinute] = start.split(":");
    startTime.setHours(startHour, startMinute, 0, 0);
  
    return startTime - currentTime; // Devuelve el tiempo restante en milisegundos
  };
  

 
  
  

  const checkInactivity = (inactiveDays, inactiveHours) => {
    const currentDay = new Date().toLocaleString("en-us", { weekday: "long" }).toLowerCase();
    const currentTime = new Date().toTimeString().substring(0, 5); // Obtener la hora actual en formato HH:MM
  
    if (inactiveDays && inactiveDays.includes(currentDay)) {
      const { start, end } = inactiveHours || {};
  
      if (start && end && currentTime >= start && currentTime <= end) {
        console.log("El sorteo está inactivo debido al horario.");
        setIsInactive(true); // Cambia el estado a inactivo
      } else {
        if (isInactive) {
          console.log("El sorteo ha vuelto a estar activo.");
        }
        setIsInactive(false); // Cambia el estado a activo
      }
    } else {
      setIsInactive(false); // Cambia el estado a activo si no es día de inactividad
    }
  };

  
  


  // Al cargar el componente, revisar si ya se disparó el evento de eliminación
  useEffect(() => {
    if (isInactive) {
      // Si el sorteo acaba de cambiar a inactivo y no se ha disparado el evento, borra la colección
      console.log("El sorteo se volvió inactivo, eliminando la colección 'clientes'.");
   }
  }, [isInactive]); // Solo se ejecuta cuando el estado 'isInactive' cambia
  

  const validatePhoneNumber = async (phoneNumber) => {
    try {
      if (!sorteo) return;
      const clientRef = doc(db, "clientes", phoneNumber);
      const clientSnap = await getDoc(clientRef);

      if (clientSnap.exists()) {
        const clientData = clientSnap.data();
        if (sorteo.esGratuito) {
          if (clientData.participacionesGratuitas && clientData.participacionesGratuitas.length > 0) {
            setIsPhoneValid(false);
            setHasParticipatedInOtherFreeRaffles(true);
            toast.warn(
              "Este número ya ha registrado un boleto en otro sorteo gratuito. Para dar oportunidad a otros, solo puedes participar en un sorteo gratuito."
            );
            return false;
          }
        }
      }

      setIsPhoneValid(true);
      setHasParticipatedInOtherFreeRaffles(false);
      return true;
    } catch (error) {
      console.error("Error al validar el número de teléfono:", error);
      toast.error("Hubo un error al validar el número de teléfono. Por favor, intenta de nuevo.");
      return false;
    }
  };

  

  const fetchClientName = async (phoneNumber) => {
    try {
      const clientRef = doc(db, "clientes", phoneNumber);
      const clientSnap = await getDoc(clientRef);

      if (clientSnap.exists()) {
        const clientData = clientSnap.data();
        setUsuario((prevUsuario) => ({
          ...prevUsuario,
          nombre: clientData.nombre || "",
        }));
      } else {
        setUsuario((prevUsuario) => ({
          ...prevUsuario,
          nombre: "",
        }));
      }
    } catch (error) {
      console.error("Error al obtener el nombre del cliente:", error);
    }
  };

  const handlePhoneChange = async (value) => {
    const phoneWithoutCode = value.replace(/^\+52|^\+1/, "");
    setUsuario({ ...usuario, telefono: phoneWithoutCode });

    if (phoneWithoutCode.length < 10) {
      setIsPhoneValid(false);
      return;
    }

    const isValid = await validatePhoneNumber(phoneWithoutCode);
    if (isValid) {
      await fetchClientName(phoneWithoutCode);
    }
  };

  const sendHeightToParent = () => {
    const height = document.documentElement.scrollHeight || document.body.scrollHeight;
    window.parent.postMessage(
      {
        type: "resizeIframe",
        height: height,
      },
      "*"
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (sorteo && sorteo.inactiveDays && sorteo.inactiveHours) {
        checkInactivity(sorteo.inactiveDays, sorteo.inactiveHours);
      }
    }, 10000); // Verifica cada 10 segundos

    return () => clearInterval(interval); // Limpia el intervalo cuando se desmonte el componente
  }, [sorteo]);

  const handleSelectBoleto = (boleto) => {
    if (isInactive) {
      toast.warn("El sorteo está inactivo en este momento.");
      return;
    }
    if (boleto.estado === "disponible" && (!sorteo.esGratuito || seleccionados.length === 0)) {
      const limite = selectedPackage || sorteo.limitePorPersona;

      if (limite && seleccionados.length >= limite) {
        toast.warn(`Solo puedes seleccionar hasta ${limite} números.`);
        return;
      }

      setSeleccionados([...seleccionados, boleto.numero]);
      setBoletos(
        boletos.map((b) =>
          b.numero === boleto.numero ? { ...b, estado: "seleccionado" } : b
        )
      );
      toast.success(`Número ${boleto.numero} agregado a la selección.`);
    } else if (sorteo.esGratuito && seleccionados.length > 0) {
      toast.warn("Solo puedes seleccionar un número en sorteos gratuitos.");
    }

    setSearchTerm("");
    setTempBoletos([]);
    setIsNumberFound(true);
    sendHeightToParent();
  };

  const sendToGoogleSheets = async (data, telefonoConFormato) => {
    const fechaActual = new Date().toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "long",
    });
  
    const tituloConFecha = `${sorteo.nombre} ${fechaActual}`;
  
    const dataToSend = {
      nombre: usuario.nombre,
      telefono: telefonoConFormato, // Pasamos el número de teléfono con formato
      boletos: seleccionados,
      sorteo: tituloConFecha, 
      total: seleccionados.length * sorteo.costo,
      fecha: new Date().toLocaleString("es-ES", {
        timeZone: "America/Mexico_City",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
      esGratuito: sorteo.esGratuito,
    };
  
    try {
      const response = await fetch(`${API_URL}/add-row`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });
  
      if (response.ok) {
        console.log("Datos enviados correctamente a Google Sheets");
      } else {
        console.error("Error al enviar los datos a Google Sheets");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  const createNewBoletos = async (cantidad, disponiblesRef) => {
    try {
      const disponiblesDoc = await getDoc(disponiblesRef);
      const sorteoDocRef = doc(db, "sorteos", id);
      const sorteoDoc = await getDoc(sorteoDocRef);

      if (sorteoDoc.exists() && disponiblesDoc.exists()) {
        const disponiblesData = disponiblesDoc.data();
        const sorteoData = sorteoDoc.data();

        if (typeof sorteoData.cantidadActual === "undefined" || sorteoData.cantidadActual === null) {
          console.error("cantidadActual está indefinido o es nulo");
          throw new Error("cantidadActual está indefinido o es nulo");
        }

        let nuevaCantidadActual = parseInt(sorteoData.cantidadActual, 10);
        if (isNaN(nuevaCantidadActual)) {
          console.error("El valor de cantidadActual no es un número válido:", sorteoData.cantidadActual);
          throw new Error("cantidadActual no es un número válido.");
        }

        let nuevosNumeros = [];

        for (let i = 0; i < cantidad; i++) {
          nuevaCantidadActual += 1;

          if (nuevaCantidadActual > disponiblesData.cantidadMaxima) break;

          nuevosNumeros.push(nuevaCantidadActual.toString().padStart(5, "0"));
        }

        if (nuevosNumeros.length > 0) {
          await updateDoc(disponiblesRef, {
            numeros: arrayUnion(...nuevosNumeros),
          });

          await updateDoc(sorteoDocRef, {
            cantidadActual: nuevaCantidadActual,
          });

          return true;
        } else {
          console.error("No se pudieron generar nuevos números.");
          return false;
        }
      } else {
        console.error("El documento de boletos disponibles o de sorteo no existe.");
        return false;
      }
    } catch (error) {
      console.error("Error al crear nuevos boletos:", error);
      return false;
    }
  };

  const handleComprarBoletos = async () => {
    if (isInactive) {
      toast.warn("El sorteo está inactivo en este momento.");
      return;
    }
    if (usuario.telefono.length < 10) {
      toast.warn("El número de teléfono debe contener al menos 10 dígitos.");
      return;
    }

    if (hasParticipatedInOtherFreeRaffles || !isPhoneValid) {
      return;
    }

    setSubmitting(true);

    try {
      if (!usuario.nombre || !usuario.telefono) {
        toast.warn("Por favor, ingrese su nombre y teléfono.");
        setSubmitting(false);
        return;
      }

      const disponiblesRef = doc(db, `sorteos/${id}/boletos`, "disponibles");

      const newBoletosCreated = await createNewBoletos(seleccionados.length, disponiblesRef);

      if (newBoletosCreated) {
        let telefonoConFormato = `+${usuario.telefono}`;
        if (telefonoConFormato.startsWith("+52") && !telefonoConFormato.startsWith("+521")) {
          telefonoConFormato = telefonoConFormato.replace("+52", "+521");
        }

        const data = {
          nombre: usuario.nombre,
          telefono: telefonoConFormato,
          boletos: seleccionados,
          sorteo: sorteo.nombre,
          total: seleccionados.length * sorteo.costo,
          fecha: new Date().toLocaleString("es-ES", {
            timeZone: "America/Mexico_City",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
          esGratuito: sorteo.esGratuito,
        };

        await updateDoc(disponiblesRef, {
          numeros: arrayRemove(...seleccionados),
        });

        const compradosRef = doc(db, `sorteos/${id}/boletos`, "comprados");
        await updateDoc(compradosRef, {
          numeros: arrayUnion(...seleccionados),
        });


        await sendToGoogleSheets(data, telefonoConFormato);


        const clientRef = doc(db, "clientes", usuario.telefono);
        await setDoc(
          clientRef,
          {
            nombre: usuario.nombre,
            participacionesGratuitas: arrayUnion(id),
            boletosRegistrados: arrayUnion(...seleccionados), // Agregamos los boletos registrados
          },
          { merge: true }
        );
        

        if (sorteo.esGratuito) {
          const sorteoDocRef = doc(db, "sorteos", id);
          await updateDoc(sorteoDocRef, {
            telefonosRegistrados: arrayUnion(usuario.telefono),
          });
        }

        if (sorteo.redirectToExternalPage && sorteo.externalPageUrl) {
          const queryParams = new URLSearchParams(data).toString();

          window.parent.postMessage(
            {
              type: "redirect",
              url: `${sorteo.externalPageUrl}?${queryParams}`,
            },
            "*"
          );
        } else {
          toast.success("Boletos registrados exitosamente.");
          navigate("/gracias");
        }
      } else {
        setSubmitting(false);
      }
    } catch (error) {
      console.error("Error al preparar la compra de boletos:", error);
      toast.error("Error al preparar la compra de boletos");
      setSubmitting(false);
    }
  };

  const handleRemoveSeleccionado = (boleto) => {
    setSeleccionados(seleccionados.filter((b) => b !== boleto));
    setBoletos(
      boletos.map((b) =>
        b.numero === boleto ? { ...b, estado: "disponible" } : b
      )
    );
    toast.info(`Número ${boleto} eliminado de la selección.`);
    sendHeightToParent();
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
  
    // Permitir solo números y limitar a 4 dígitos
    if (/^\d{0,4}$/.test(value)) {
      setSearchTerm(value);
  
      const formattedNumber = value.padStart(4, "0"); // Ajustar a 4 dígitos
      const found = boletos.some((boleto) => boleto.numero === formattedNumber);
      setIsNumberFound(found);
  
      if (!found && value) {
        setTempBoletos([{ numero: formattedNumber, estado: "disponible" }]);
      } else {
        setTempBoletos([]);
      }
  
      if (sorteo.esGratuito && seleccionados.length >= 1) {
        setDisableGridSelection(true);
      } else {
        setDisableGridSelection(false);
      }
    }
    sendHeightToParent();
  };
  
  const handleAddNumber = () => {
    if (!isNumberFound && searchTerm !== "" && (!sorteo.esGratuito || seleccionados.length === 0)) {
      const formattedNumber = searchTerm.padStart(5, "0");
      setSeleccionados([...seleccionados, formattedNumber]);
      toast.success(`Número ${formattedNumber} agregado a la selección.`);

      if (sorteo.esGratuito) {
        setDisableGridSelection(true);
      }

      setSearchTerm("");
      setTempBoletos([]);
      setIsNumberFound(true);
      sendHeightToParent();
    }
  };

  const handleNextPage = () => {
    const newIndex = pageIndex + 1;
    setPageIndex(newIndex);
    fetchBoletos(newIndex, showOnlyAvailable);
    sendHeightToParent();
  };

  const handlePreviousPage = () => {
    if (pageIndex > 0) {
      const newIndex = pageIndex - 1;
      setPageIndex(newIndex);
      fetchBoletos(newIndex, showOnlyAvailable);
      sendHeightToParent();
    }
  };

  const closePackageModal = () => {
    setShowPackageModal(false);
    sendHeightToParent();
  };

  const selectPackageAndClose = (packSize) => {
    setSelectedPackage(packSize);
    closePackageModal();
    sendHeightToParent();
  };

  const handlePlayVideo = () => {
    setVideoPlaying(true);
    sendHeightToParent();
  };

  const handleToggleAvailable = () => {
    setShowOnlyAvailable(!showOnlyAvailable);
    fetchBoletos(0, !showOnlyAvailable);
  };

  const filteredBoletos = [...boletos, ...tempBoletos].filter((boleto) =>
    boleto.numero.includes(searchTerm)
  );

  return (
    <div className="sorteo-container p-4 bg-black text-white min-h-screen flex flex-col items-center relative">
      {sorteo ? (
        <>
          {isInactive ? (
            <div className="overlay fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
              <div className="text-center">
                <h1 className="text-3xl font-bold text-white mb-4">Este sorteo ha terminado por hoy.</h1>
                <p className="text-xl text-white">Vuelve mañana para participar de nuevo.</p>
              </div>
            </div>
          ) : (
            <>
              {sorteo.mostrarTitulo && (
                <h1 className="text-4xl font-bold mb-4">{sorteo.nombre}</h1>
              )}
              {sorteo.mostrarDescripcion && (
                <p className="text-lg mb-4">{sorteo.descripcion}</p>
              )}
              {!videoPlaying && sorteo.mediaType === "video" && sorteo.mediaLink ? (
                <div className="relative mb-4 w-full h-auto object-cover" style={{ maxHeight: "620px" }}>
                  <img
                    src={sorteo.posterLink || sorteo.mediaLink}
                    alt="Portada del video"
                    className="w-full h-auto object-cover cursor-pointer"
                    onClick={handlePlayVideo}
                  />
                  <div
                    className="absolute inset-0 flex items-center justify-center"
                    onClick={handlePlayVideo}
                  >
                    <button className="bg-black bg-opacity-50 text-white text-6xl p-4 rounded-full">
                      ▶️
                    </button>
                  </div>
                </div>
              ) : (
                sorteo.mediaLink && (
                  <video
                    src={sorteo.mediaLink}
                    controls
                    className="mb-4 w-full h-auto object-cover"
                    style={{ maxHeight: "620px" }}
                    autoPlay
                  />
                )
              )}
              {expired ? (
                <div className="overlay">
                  <p className="overlay-text">Este sorteo ya ha finalizado.</p>
                </div>
              ) : (
                <>
                  {sorteo.fechaFin && (
                    <>
                      <p className="text-lg font-bold mt-1">Este sorteo termina en:</p>
                      <Countdown targetDate={sorteo.fechaFin} />
                    </>
                  )}
                  <div
                    style={{ marginTop: "-20px" }}
                    className="bg-gray-800 p-4 rounded-lg shadow-lg w-full mt-6"
                  >
                    <div className="mb-4 form-row">
                      <div className="form-group">
                        <label className="form-label"></label>
                        <PhoneInput
                          country={usuario.pais}
                          value={usuario.telefono}
                          onChange={handlePhoneChange}
                          onlyCountries={["mx", "us"]}
                          countryCodeEditable={false}
                          inputStyle={{
                            width: "100%",
                            padding: "0.75rem 0.75rem 0.75rem 3.5rem",
                            borderRadius: "0.375rem",
                            borderColor: "#d1d5db",
                            backgroundColor: "#fff",
                            color: "#000",
                            fontSize: "16px",
                          }}
                          buttonStyle={{
                            borderRadius: "0.375rem 0 0 0.375rem",
                            borderColor: "#d1d5db",
                            backgroundColor: "#f0f0f0",
                            padding: "0 12px",
                          }}
                          containerStyle={{
                            width: "100%",
                            backgroundColor: "#fff",
                            borderRadius: "0.375rem",
                            border: "1px solid #d1d5db",
                            display: "flex",
                            alignItems: "center",
                          }}
                          dropdownStyle={{
                            backgroundColor: "#fff",
                            borderRadius: "0.375rem",
                            zIndex: 1000,
                          }}
                          searchStyle={{
                            margin: "0.5rem",
                            padding: "0.5rem",
                            borderRadius: "0.375rem",
                            borderColor: "#d1d5db",
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-4 form-row">
                      <div className="form-group">
                        <label className="form-label"></label>
                        <input
                          type="text"
                          style={{ marginTop: "-10px" }}
                          placeholder="Nombre"
                          value={usuario.nombre}
                          onChange={(e) => setUsuario({ ...usuario, nombre: e.target.value })}
                          className="p-2 border border-gray-600 rounded mb-2 w-full form-input"
                        />
                      </div>
                    </div>
                    {seleccionados.length > 0 && (
                      <div style={{ marginBottom: "20px" }} className="mt-4">
                        <button
                          onClick={handleComprarBoletos}
                          className={`marquee-button ${
                            seleccionados.length > 0 && !hasParticipatedInOtherFreeRaffles
                              ? "active"
                              : ""
                          }`}
                          disabled={sorteo.esGratuito && (hasParticipatedInOtherFreeRaffles || !isPhoneValid)}
                          style={{
                            backgroundColor: hasParticipatedInOtherFreeRaffles ? "gray" : "",
                            boxShadow: hasParticipatedInOtherFreeRaffles ? "none" : "",
                            color: hasParticipatedInOtherFreeRaffles ? "#ffffff" : "",
                          }}
                        >
                          {hasParticipatedInOtherFreeRaffles
                            ? "Ya no se puede usar este teléfono"
                            : "Registrar Boletos"}
                        </button>
                      </div>
                    )}
                    <div
                      style={{ transform: " translate(0,0px)" }}
                      className={sorteo.esGratuito === false ? "columns-2xl" : ""}
                    >
                      <div className="flex mb-4 justify-center items-center">
                      <input
  type="text"
  placeholder="Buscar número"
  value={searchTerm}
  onChange={handleSearchChange}
  maxLength={4} // Limitar a 4 dígitos
  className="p-2 border border-gray-600 rounded mr-2 w-full max-w-xs"
/>
                        <button
                          onClick={
                            isNumberFound || (sorteo.esGratuito && seleccionados.length > 0)
                              ? null
                              : handleAddNumber
                          }
                          className={`p-2 rounded text-white ${
                            isNumberFound || (sorteo.esGratuito && seleccionados.length > 0)
                              ? "bg-blue-500"
                              : "bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 animate-pulse animated-button"
                          }`}
                          disabled={isNumberFound || (sorteo.esGratuito && seleccionados.length > 0)}
                        >
                          {isNumberFound || (sorteo.esGratuito && seleccionados.length > 0)
                            ? "Buscar"
                            : "Agregar"}
                        </button>
                      </div>
                      {sorteo.esGratuito === true ? (
                        ""
                      ) : (
                        <p className="text-lg font-bold mt-4">
                          {" "}
                          Total: ${seleccionados.length * sorteo.costo}{" "}
                        </p>
                      )}
                    </div>
                    <div className="flex justify-center space-x-4 mt-4 mb-4">
                      <div className="flex items-center space-x-2">
                        <div className="w-8 h-5 bg-gray-500 rounded"></div>
                        <span className="text-white-500">Grises No Disponibles</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="w-8 h-5 bg-blue-600 rounded"></div>
                        <span className="text-white-600">Azules Disponibles</span>
                      </div>
                    </div>
                    <div className="content-center mb-4 ">
                      <label className=" align-middle text-white mr-2">Mostrar solo disponibles:</label>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={showOnlyAvailable}
                          onChange={handleToggleAvailable}
                          className="sr-only peer  align-middle"
                        />
                        <div className="w-14 h-7 bg-gray-400 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer-checked:bg-blue-600 transition-all duration-300"></div>
                        <div className="absolute w-6 h-6 bg-white rounded-full shadow-md transform peer-checked:translate-x-7 peer-checked:bg-white transition-transform duration-300"></div>
                      </label>
                    </div>
                    <div className="flex flex-wrap mb-4 justify-center">
                      {seleccionados.map((boleto) => (
                        <div key={boleto} className="m-1 flex items-center bg-green-600 p-2 rounded text-white">
                          <span className="px-2 py-1">{boleto}</span>
                          <button
                            onClick={() => handleRemoveSeleccionado(boleto)}
                            className="ml-2 text-white"
                          >
                            <GoTrash />
                          </button>
                        </div>
                      ))}
                    </div>
                    <div style={{ transform: " translate(0,-25px) " }} className="grid-container-wrapper">
                      <button
                        onClick={handlePreviousPage}
                        className="navigation-arrow left-arrow"
                        disabled={pageIndex === 0}
                      >
                        <FaChevronLeft />
                      </button>
                      <div className="grid-container mb-4" style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(60px, 1fr))', gap: '8px' }}>
  {filteredBoletos.map((boleto) => (
    <button
      key={boleto.numero}
      className={`p-2 rounded w-16 h-10  ${
        boleto.estado !== "disponible" || disableGridSelection
          ? "bg-gray-500 cursor-not-allowed"
          : "bg-blue-600 text-white cursor-pointer"
      }`}
      onClick={() => handleSelectBoleto(boleto)}
      disabled={boleto.estado !== "disponible" || disableGridSelection}
    >
      {boleto.numero}
    </button>
  ))}
</div>


                      <button
                        onClick={handleNextPage}
                        className="navigation-arrow right-arrow"
                        disabled={filteredBoletos.length < pageSize}
                      >
                        <FaChevronRight />
                      </button>
                    </div>
                    {loading && <p>Cargando...</p>}
                  </div>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <p>Cargando...</p>
      )}
      {submitting && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="text-center">
            <img src={cargaGif} alt="Cargando..." />
            <p className="text-white text-xl font-bold mt-4">Registrando boletos...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sorteo;
