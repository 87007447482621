import React, { useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';
import datos from './datos.json';
import logo from '../assets/logo.png';
import confetiGif from '../assets/confeti.gif'; // GIF si se encuentra en Firebase
import confetiAlternativoGif from '../assets/confeti_alternativo.gif'; // Nuevo GIF si es generado aleatoriamente

const Ganadores = () => {
  const [numeroSorteo, setNumeroSorteo] = useState('');
  const [ganadores, setGanadores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [isFromDatabase, setIsFromDatabase] = useState(false); // Nueva variable para controlar si el ganador es de Firebase

  // Función para enmascarar el teléfono
  const enmascararTelefono = (telefono) => {
    if (telefono.length > 4) {
      const inicio = telefono.slice(0, 2);
      const fin = telefono.slice(-4);
      return `${inicio}******${fin}`;
    }
    return telefono;
  };

  // Función para generar "Multiplico" aleatorio
  const generarMultiplico = () => {
    const opciones = ["Sí", "No"];
    return opciones[Math.floor(Math.random() * opciones.length)];
  };

  // Función para generar "Premio" en base a la opción "Multiplico"
  const generarPremio = (multiplico) => {
    if (multiplico === "No") {
      return "$2,000"; // Si "Multiplico" es "No", el premio es $2,000
    } else {
      const opcionesPremio = ["$20,000", "$40,000"];
      return opcionesPremio[Math.floor(Math.random() * opcionesPremio.length)];
    }
  };

  // Función para generar nombre aleatorio
  const generarNombreAleatorio = () => {
    const nombres = ["María Fernanda", "Ana Sofía"]; // Los nombres que ya tienes
    const apellidos = ["García", "Martínez"]; // Los apellidos que ya tienes
    return `${nombres[Math.floor(Math.random() * nombres.length)]} ${apellidos[Math.floor(Math.random() * apellidos.length)]}`;
  };

  // Función para generar "Número no registrado" con una probabilidad del 70%
  const generarGanadorAleatorio = () => {
    const probabilidad = Math.random();  // Generar un número aleatorio entre 0 y 1
    console.log("Probabilidad generada:", probabilidad); // Para depuración
  
    if (probabilidad < 0.7) {
      return "Número no registrado";
    } else {
      return generarNombreAleatorio();
    }
  };

  // Función para generar número de sorteo aleatorio de 4 dígitos
  const generarNumeroSorteoAleatorio = () => {
    const numeroAleatorio = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); // Genera un número de 4 dígitos
    return numeroAleatorio;
  };

  // Función para generar número de teléfono aleatorio (10 dígitos)
  const generarTelefonoAleatorio = () => {
    return `52${Math.floor(Math.random() * 1000000000).toString().padStart(10, '0')}`; // Prefijo de México + número completo
  };

  const handleBuscarGanador = async () => {
    if (!numeroSorteo) {
      toast.warn('Por favor, ingresa un número de sorteo para buscar.');
      return;
    }
    
    setLoading(true);
    setGanadores([]); // Limpiar el estado antes de la búsqueda
    setShowConfetti(false);
    
    try {
      const clientesRef = collection(db, 'clientes');
      const q = query(clientesRef, where('boletosRegistrados', 'array-contains', numeroSorteo));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        // Tomamos solo el primer ganador de la base de datos para evitar duplicaciones
        const primerGanador = querySnapshot.docs[0].data(); // Tomamos el primer documento encontrado
        const ganador = {
          nombre: primerGanador.nombre || 'No disponible',
          telefono: enmascararTelefono(querySnapshot.docs[0].id), // Enmascarar el número de teléfono aquí
          boletos: primerGanador.boletosRegistrados ? primerGanador.boletosRegistrados.join(', ') : 'Ninguno',
          multiplico: "Revisar", // Mostramos "Revisar" si está en la base de datos
          premio: "Revisar", // Mostramos "Revisar" si está en la base de datos
        };
  
        setGanadores([ganador]); // Mostramos solo un ganador
        setIsFromDatabase(true); // Indicamos que es de Firebase
        setShowConfetti(true); // Mostramos confetti porque es un ganador real
        toast.success('Ganador encontrado en la base de datos');
      } else if (datos[numeroSorteo]) {
        // Si no se encuentra en Firebase, buscar en el archivo JSON
        const nombreJson = datos[numeroSorteo].nombre;
        const telefonoJson = enmascararTelefono(datos[numeroSorteo].telefono);
        const multiplico = generarMultiplico(); // Generamos "Multiplico"
        setGanadores([
          {
            nombre: nombreJson,
            telefono: telefonoJson,
            boletos: numeroSorteo,
            multiplico, // Guardamos el valor de "Multiplico"
            premio: generarPremio(multiplico), // Calculamos el premio basado en "Multiplico"
          },
        ]);
        setIsFromDatabase(true);
        setShowConfetti(true); // Mostramos confetti porque es un ganador real (JSON)
        toast.success(`Número encontrado en el JSON: ${nombreJson}`);
      } else {
        // Si no está en Firebase ni en JSON, generamos un nuevo ganador o mostramos "Número no registrado"
        const nuevoNombre = generarGanadorAleatorio(); // Aplicamos la lógica para "Número no registrado"
        const nuevoTelefono = nuevoNombre === "Número no registrado" ? "N/A" : generarTelefonoAleatorio();
        const nuevoNumeroSorteo = generarNumeroSorteoAleatorio();  // Número de sorteo de 4 dígitos
        const multiplico = generarMultiplico(); // Generamos "Multiplico"
        setGanadores([
          {
            nombre: nuevoNombre,
            telefono: nuevoTelefono,
            boletos: nuevoNumeroSorteo,
            multiplico: nuevoNombre === "Número no registrado" ? "N/A" : multiplico,
            premio: nuevoNombre === "Número no registrado" ? "N/A" : generarPremio(multiplico),
          },
        ]);
        setIsFromDatabase(false);
        setShowConfetti(nuevoNombre !== "Número no registrado");
        toast.success(nuevoNombre === "Número no registrado" ? 'Número no registrado.' : 'Número generado aleatoriamente');
      }
    } catch (error) {
      console.error('Error al buscar el número:', error);
      toast.error('Hubo un error al buscar el número.');
    }
  
    setLoading(false);
  };
  
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <img src={logo} alt="Logo" className="w-32 mb-6" />

      <div className="w-full max-w-lg">
        <input
          type="text"
          placeholder="Ingresa el número del sorteo"
          value={numeroSorteo}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, '');
            if (value.length <= 4) {  // Limitar el valor a 4 dígitos
              setNumeroSorteo(value);
            }
          }}
          maxLength="4" // Limitar a 4 dígitos
          className="w-full p-3 mb-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          onClick={handleBuscarGanador}
          className={`w-full p-3 bg-blue-600 text-white rounded-md font-bold text-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={loading}
        >
          {loading ? 'Buscando...' : 'Buscar Número'}
        </button>

        {showConfetti && (
          <div className="absolute inset-0 flex justify-center items-center">
            <img 
              src={isFromDatabase ? confetiGif : confetiAlternativoGif} 
              alt="Confeti" 
              className="w-full h-full object-cover" 
            />
          </div>
        )}

        {ganadores.length > 0 && (
          <div className="mt-8 p-6 bg-white shadow-lg rounded-lg text-center">
            {ganadores[0].nombre === "Número no registrado" ? (
              <h2 className="text-2xl font-bold mb-4">Número no registrado</h2>
            ) : (
              <>
                <h2 className="text-xl font-bold mb-4">¡Ganador Encontrado!</h2>
                {ganadores.map((ganador, index) => (
                  <div key={index} className="mb-4">
                    <h3 className="text-lg mb-2"><strong>Nombre:</strong> {ganador.nombre}</h3>
                    <p className="text-lg mb-2"><strong>Teléfono:</strong> {ganador.telefono}</p>
                    <p className="text-lg mb-2"><strong>Número:</strong> {ganador.boletos}</p>
                    <p className="text-lg mb-2"><strong>Multiplico:</strong> {ganador.multiplico}</p>
                    <p className="text-lg mb-2"><strong>Premio:</strong> {ganador.premio}</p>
                  </div>
                ))}
              </>
            )}
          </div>
        )}

        {ganadores.length === 0 && !loading && numeroSorteo && (
          <p className="mt-4 text-lg text-gray-700">Introduce un número de sorteo para buscar el ganador.</p>
        )}
      </div>
    </div>
  );
};

export default Ganadores;
